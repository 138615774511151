
import { Component, Vue } from "vue-property-decorator";
import VueWeather from "./VueWeather.vue";
import LogbookWidget from "./LogbookWidget.vue";

@Component({
  components: {
    VueWeather,
    LogbookWidget,
  },
})
export default class WidgetsMenu extends Vue {}
