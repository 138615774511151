
import moment from 'moment'

export default {
    data: function(){
        return{
            formatting : {
                date: function (value, format) {
                    if (value) {
                        // tslint:disable-next-line:no-console
                        return moment(String(value)).format(format || 'DD/MM/YY')
                        //return value ;
                    }
                },
                time: function (value, format) {
                    if (value) {
                        return moment(String(value)).format(format || 'HH:mm');
                    }
                },
                default: function(value)
                {
                    return value;
                }

            }
        }
    },
    props:['value', 'fn', 'format'],
    //TODO: Controlar tipos no definidos
    computed:{
        formatter(){
            return this.formatting;
        }
    }
}


