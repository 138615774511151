<template>
  <v-container fluid >
    <v-layout text-xs-center wrap fill-height>
      <v-flex xs12 >
         <svgicon icon="logohome" width="950" height="900" color="var(--v-secondary-base)"></svgicon>
        <div class="text-center">
          <div class="vld-parent">
            <loading :active.sync="isLoading" 
              :can-cancel="true" 
              :on-cancel="onCancel"
              :is-full-page="fullPage"></loading>
          </div>
        </div>  
      </v-flex>
    </v-layout>
    
    <!--<v-footer height="auto" color="transparent" app>
      <v-container fluid>
        <v-layout justify-center row>
          <v-flex md6>
            <v-img :src="require('@/assets/logos-innobonos-2019-normal.png')" aspect-ratio="10" contain />
          </v-flex>
        </v-layout>
      </v-container>
    </v-footer>-->
  </v-container>
</template>

<script>
import '../compiled-icons/logocb';
import Loading from 'vue-loading-overlay';
 import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
  
   data() {
            return {
                isLoading: false,
                fullPage: true
            }
        },
        components: {
            Loading
        },
         mounted(){
           this.doAjax();
         },
        methods: {
            doAjax() {
                this.isLoading = true;
                // simulate AJAX
                setTimeout(() => {
                  this.isLoading = false,
                  this.gomapa();
                },5000)
            },
            onCancel() {
              console.log('User cancelled the loader.')
            },
            gomapa(){
              this.$router.push('/mapa')  
            }
        }
  }
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}


</style>
