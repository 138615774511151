<template>
  <v-touch @swipeleft="touchScreen()">
    <div class="detaBarra row">
      <v-card class="col-4 pa-2" style="height: 56px;background-color: #283A5E;"
        ><v-btn to="/mapa"
          ><svgicon
            icon="mapa"
            width="30"
            height="30"
            color="white"
          ></svgicon></v-btn
      ></v-card>
      <v-card
        class="col-4 pa-2"
        style="height: 56px;text-align:center; background-color: #283A5E;"
        ><v-btn to="/"><img src="../assets/logo-cb.png"/></v-btn
      ></v-card>
      <v-card
        class="col-4 pa-2 text-right"
        style="height: 56px;background-color: #283A5E;"
        ><v-btn to="/salidas"
          ><svgicon
            icon="salida"
            width="30"
            height="30"
            color="white"
          ></svgicon></v-btn
      ></v-card>
      <div class="titlePage">
        <svgicon icon="entradas" width="30" height="30" color="white"></svgicon
        >&nbsp;ENTRADAS
      </div>

      <v-container fluid pa-4 fill-height class="primary_container">
        <v-layout row>
          <v-flex grow pa-0>
            <DataTable
              title="ENTRADAS"
              v-bind:elemnents="this.dataNumbers"
              v-bind:headers="this.headers"
              itemKey="Imo"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </v-touch>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue"; // @ is an alias to /src
import * as jsonData from "../assets/data";
import axios from "axios";
import authService from "@/services/authService";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      expand: false,
      dataNumbers: [],

      headers: [
        { text: "Imo", value: "Imo", sortable: true },
        { text: "Buque", value: "Name", sortable: true },
        {
          text: "Siguiente puerto",
          value: "NextPortCode",
          sortable: true,
          classAttribute: "sentidoCodigo",
        },
        {
          text: "Número de escala",
          value: "PortcallNumber",
          sortable: true,
          classAttribute: "sentidoCodigo",
        },
        {
          text: "Puerto anterior",
          value: "PreviusPortCode",
          sortable: true,
          classAttribute: "sentidoCodigo",
        },
        {
          text: "Estado",
          value: "Status",
          sortable: true,
          classAttribute: "sentidoCodigo",
        },
        {
          text: "Terminal",
          value: "TerminalPortName",
          sortable: true,
          classAttribute: "sentidoCodigo",
        },
        {
          text: "Agente portuario",
          value: "VesselAgent",
          sortable: true,
          classAttribute: "sentidoCodigo",
        },
        {
          text: "Voyage",
          value: "Voyage",
          sortable: true,
          classAttribute: "sentidoCodigo",
        },
      ],
    };
  },
  created() {
    const requestBody = {
      UserLogin: process.env.VUE_APP_USER,
      Password: process.env.VUE_APP_PASS,
      Port: process.env.VUE_APP_PUERTO,
      Type: "in",
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(authService.getUserLogged()).token,
      },
    };
    axios
      .post(
        process.env.VUE_APP_API + "/api/previsiones",
        JSON.stringify(requestBody),
        config
      )
      .then((res) => {
        console.log(res.data.data);
        this.dataNumbers = res.data.data;
      });
  },
  methods: {
    navigate(destiny) {
      this.$router.push("/" + destiny);
    },
    touchScreen() {
      console.log("aqui");
    },
  },
};
</script>
<style scoped>
.iconPopClose {
  background-color: black;
  border-radius: 50px;
}
h2 {
  line-height: 1 !important;
  font-size: 12pt !important;
}
.arrow_box {
  position: relative;
  background: green;
  border: 1 px solid green;
}

.arrow_boxS {
  position: relative;
  background: #abb7b7;
  border: 1 px solid #abb7b7;
}

.arrow_boxE {
  position: relative;
  background: #b877db;
  border: 1 px solid #b877db;
}

.arrow_boxM {
  position: relative;
  background: #f5fa5e;
  border: 1 px solid #f5fa5e;
}

.arrow_boxP {
  position: relative;
  background: #f5fa5e;
  border: 1 px solid #f5fa5e;
}

.bocadillo {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: green;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.bocadilloS {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #abb7b7;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}

.bocadilloE {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #293a5e;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}

.bocadilloM {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #abed00;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}
.bocadilloP {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #f5fa5e;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.bocadilloPU {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #ff4b66;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.TituloShip {
  color: white;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size: 9pt;
}

.TituloShipP {
  color: black;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size: 9pt;
}

.bocadillo .textoBocadillo,
.bocadilloS .textoBocadillo,
.bocadilloE .textoBocadillo,
.bocadilloM .textoBocadillo,
.bocadilloP .textoBocadillo,
.bocadilloPU .textoBocadillo {
  background-color: #f0fed3;
  color: black;
  line-height: 0.5;
  /*margin-top: 7px;*/
  font-size: 11pt;
  padding: 11px;
  padding-bottom: 1px;
  border-radius: 10px;
  padding-left: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.dialogBox {
  width: 100%;
  height: 219px !important;
}
.botonClose {
  width: "100%";
  text-align: right;
}

.logoAmura {
  max-width: 150px;
}

.botonClosePopup {
  margin-top: -44px;
  margin-left: 176px;
  clear: both;
  position: absolute;
}

.txtCard {
  width: "100%";
  text-align: center !important;
}
.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
  background: white !important;
  color: black !important;
}
.v-card {
  width: 100%;
  height: 200px;
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
button {
  width: 20%;
  height: 54px;
}
.pin {
  width: 75%;
}

.popupFlexCont {
  width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.popupFlexFoto {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.popupFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.itemBandera {
  width: 13% !important;
  border: 1px solid aqua;
  padding: 3px;
  background-color: aqua;
  border-radius: 9px;
}
.fotoBandera {
  width: 100%;
}

.itemNombreBarco {
  color: blue;
  text-transform: uppercase;
  font-weight: bolder;
}

.typeship {
  color: black;
  font-weight: 100;
  text-transform: none;
}

.iconoRotacion {
  transform: rotateY(60deg);
}

.tile,
tile:hover {
  color: "primary";
}
.tile:active {
  color: "white";
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #293a5e !important;
  box-shadow: none !important;
}

.theme--light.v-btn {
  color: #293a5e !important;
}

.v-toolbar__content {
  padding: 0px !important;
}

.borde {
  border: 5px white solid;
  border-radius: 25px;
}

.fondosal {
  border-color: #abb7b7;
  background-color: #abb7b7;
}

.fondoent {
  border-color: #b877db;
  background-color: #b877db;
}

.fondobc {
  border-color: #abed00;
  background-color: #abed00;
}
.fondoPOB {
  border-color: #f5fa5e;
  background-color: #f5fa5e;
}

.fondobar {
  border-color: #ff4b66;
  background-color: #ff4b66;
}

.fondobat {
  border-color: #84cbff;
  background-color: #84cbff;
}

.fondoet {
  border-color: #76c888;
  background-color: #76c888;
}

.textcolor {
  color: white;
}

.v-btn {
  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  /* align-items: center; */
  /* border-radius: 2px; */
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 6px 0px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  position: relative;
  /* vertical-align: middle; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-sheet {
  border-radius: 0px !important;
}
.fondoBarra {
  background-color: #2935a3;
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #293a5e !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.detaBarra {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 58px;
}

.entradasBtm {
  width: 2vw;
  height: 2vw;
}

.incidentBtmBtm {
  width: 2vw;
  height: 2vw;
}

.buquesBtm {
  width: 2vw;
  height: 2vw;
}

.leaflet-layer {
  z-index: 1 !important;
}

/*//////////ESTILOS PORTRAIT ////////////////////////////////*/
@media only screen and (orientation: portrait) {
  .logoAmura {
    max-width: 150px;
    margin-left: 40px;
  }

  .entradasBtm {
    width: 8vw;
    height: 5vw;
  }

  .incidentBtmBtm {
    width: 4vw;
    height: 4vw;
  }

  .buquesBtm {
    width: 4vw;
    height: 4vw;
  }

  .botonClosePopup {
    margin-top: -4vh;
    margin-left: 24.5vw;
    clear: both;
    position: absolute;
  }

  .bocadilloPU {
    /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #ff4b66;
    position: absolute;
    top: calc(500 - var(--pointsX));
    left: var(--pointsY);
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
  }
}
</style>

<style lang="scss">
.titlePage {
  width: 100%;
  height: 53px;
  background-color: #01ca6e;
  color: white;
  text-align: center;
  padding: 11px;
  font-size: 25px;
}
.container.fluid {
  max-width: 100%;
  height: 130vh !important;
  padding: 7px;
}
.v-content__wrap {
  background-color: var(--v-primary-base);
}
</style>
