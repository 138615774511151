
import { Component, Vue } from "vue-property-decorator";
import "../compiled-icons/logocb";
import WidgetsMenu from "../components/WidgtesMenu.vue";
import LogbookWidget from "./LogbookWidget.vue";

export default {
  components: {
    WidgetsMenu,
    LogbookWidget,
  },

  /*showMenu: boolean = false;
    showWidget: boolean = true;
    toggleMenu(): void {
      this.showMenu = !this.showMenu;
    }*/
  data() {
    return {
      widgets: true,
    };
  },
  methods: {
    ocultar() {
      this.widgets = !this.widgets;
    },
  },
};
