<template>
  <v-touch @swipeleft="touchScreen()">
    <div class="detaBarra row">
      <v-card
        class="col-4"
        style="height: 70px; background-color: #171c31"
      ></v-card>
      <v-card
        class="col-4"
        style="
          height: 70px;
          display: flex;
          justify-content: center;
          text-align: center;
          background-color: #171c31;
          padding-top: 10px;
        "
        ><v-btn to="/"
          ><svgicon
            icon="logocb"
            width="280"
            height="280"
            color="#fff"
          ></svgicon></v-btn
      ></v-card>
      <v-card
        class="col-4 text-right"
        style="
          height: 70px;
          display: flex;
          justify-content: flex-end;
          background-color: #171c31;
          padding-top: 10px;
          padding-right: 35px;
        "
        ><v-btn to="/maniobras"
          ><svgicon
            icon="maniobras"
            width="50"
            height="50"
            color="#c0f4b3"
          ></svgicon
        ></v-btn>
        <v-btn to="/noautorizados"
          ><svgicon
            icon="nopermitidos"
            width="50"
            height="50"
            color="#343d66"
          ></svgicon
        ></v-btn>
        <v-btn to="/mapa"
          ><svgicon
            icon="localizacion"
            width="50"
            height="50"
            color="#343d66"
          ></svgicon
        ></v-btn>
        <v-btn class="logout-button" @click="logout()"
          ><svgicon
            icon="apagado"
            width="50"
            height="50"
            color="#475988"
          ></svgicon
        ></v-btn>
      </v-card>

      <v-container fluid pa-4 fill-height class="primary_container">
        <v-layout row>
          <v-flex grow pa-0>
            <h1 class="table-title">
              <svgicon
                icon="maneuveringTitle"
                width="30"
                height="30"
                color="#c0f4b3"
              ></svgicon>
              Maniobras en curso
            </h1>
            <div class="d-flex flex-column table-container">
              <dynamic-list
                id="maneuver-list"
                :config="JSON.stringify(configManeuver)"
                :rows="JSON.stringify(rowsManeuver)"
                :offset="currentOffsetManeuver"
                :total-records="totalRecordsManeuver"
                :loading="loadingManeuver"
              ></dynamic-list>
            </div>
            <h1 class="table-title">
              <svgicon
                icon="forecastTitle"
                width="30"
                height="30"
                color="#c0f4b3"
              ></svgicon>
              Previsiones
            </h1>
            <div class="d-flex flex-column table-container">
              <dynamic-list
                id="forecast-list"
                :config="JSON.stringify(configForecast)"
                :rows="JSON.stringify(rowsForecast)"
                :offset="currentOffsetForecast"
                :total-records="totalRecordsForecast"
                :loading="loadingForecast"
              ></dynamic-list>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </v-touch>
</template>

<script>
import "../web-components/dynamic-list-component/main.js";
import "../web-components/dynamic-list-component/polyfills.js";
import mdesService from "@/services/mdesService";
import { TableResponse } from "@/models/TableResponse";
import { TableConfig } from "@/models/TableConfig";
import authService from "@/services/authService";
export default {
  name: "maneuver-list-component",

  components: {},
  data() {
    return {
      loadingFullPage: true,
      configManeuver: new TableConfig(
        {
          label: "Maniobrando",
          colorCode: "#fff",
          backgroundCode: "#252c48",
          name: "maniobrando",
        },
        [
          {
            label: "BUQUE",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "BUQUE",
          },
          {
            label: "TIPO DE MANIOBRA",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "TIPO DE MANIOBRA",
          },
          // {
          //   label: "P.O.B.",
          //   colorCode: "#fff",
          //   backgroundCode: "#2c3b5d",
          //   name: "P.O.B.",
          // },
          // {
          //   label: "E.P.OFF",
          //   colorCode: "#fff",
          //   backgroundCode: "#2c3b5d",
          //   name: "E.P.OFF",
          // },
          // {
          //   label: "PILOT",
          //   colorCode: "#fff",
          //   backgroundCode: "#2c3b5d",
          //   name: "PILOT",
          // },
          {
            label: "TUGS",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "TUGS",
          },
        ]
      ),
      loadingManeuver: "true",
      rowsManeuver: [],
      currentOffsetManeuver: 0,
      currentLimitManeuver: 10,
      totalRecordsManeuver: 10,
      configForecast: new TableConfig(
        {
          label: "Previsiones",
          colorCode: "#fff",
          backgroundCode: "#252c48",
          name: "previsiones",
        },
        [
          // {
          //   label: "SOLICITUD",
          //   colorCode: "#fff",
          //   backgroundCode: "#2c3b5d",
          //   name: "SOLICITUD",
          // },
          {
            label: "TIPO",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "TIPO",
          },
          {
            label: "ETS",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "ETS",
          },
          {
            label: "MEDIA",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "MEDIA",
          },
          {
            label: "BUQUE",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "BUQUE",
          },
          {
            label: "DESTINO",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "DESTINO",
          },
          {
            label: "BANDA",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "BANDA",
          },
          {
            label: "TUGS",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "TUGS",
          },
          {
            label: "OBSERVACIONES",
            colorCode: "#fff",
            backgroundCode: "#2c3b5d",
            name: "OBSERVACIONES",
          },
        ]
      ),
      loadingForecast: "true",
      rowsForecast: [],
      currentOffsetForecast: 0,
      currentLimitForecast: 10,
      totalRecordsForecast: 10,
      refreshInterval: null,
    };
  },
  beforeCreate() {
    mdesService
      .loginMDES(process.env.VUE_APP_USER, process.env.VUE_APP_PASS)
      .then((response) => {
        mdesService.setUserMDESLogged(response);
        this.loadManeuvering();
        this.loadForecast();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    document
      .getElementById("maneuver-list")
      .addEventListener("emitter", (e) => {
        if (
          this.currentOffsetManeuver !== e.detail.offset ||
          this.currentLimitManeuver !== e.detail.limit
        ) {
          this.currentLimitManeuver = e.detail.limit;
          this.currentOffsetManeuver = e.detail.offset;
          this.loadManeuvering();
        }
      });

    document
      .getElementById("forecast-list")
      .addEventListener("emitter", (e) => {
        if (
          this.currentOffsetForecast !== e.detail.offset ||
          this.currentLimitForecast !== e.detail.limit
        ) {
          this.currentLimitForecast = e.detail.limit;
          this.currentOffsetForecast = e.detail.offset;
          this.loadForecast();
        }
      });
    this.refreshInterval = setInterval(() => {
      this.loadManeuvering();
      this.loadForecast();
    }, process.env.VUE_APP_REFRESH_TIME_TABLE);
  },
  destroyed() {
    clearInterval(this.refreshInterval);
  },
  methods: {
    logout() {
      authService.logout();
      this.$router.replace("/login");
    },
    formatDate(date) {
      if (date == null || date == "") {
        return "";
      }
      const d = new Date(Date.parse(date));
      const day = d.getDate().toString();
      const hours = ("0" + d.getHours().toString()).slice(-2);
      const minutes = ("0" + d.getMinutes().toString()).slice(-2);
      return hours + ":" + minutes + "(" + day + ")";
    },
    handleRefresh() {
      mdesService
        .refreshMDES()
        .then((response) => {
          mdesService.setUserMDESLogged(response.data);
          this.loadManeuvering();
          this.loadForecast();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadManeuvering() {
      this.loadingManeuver = "true";
      mdesService
        .getManeuveringList(
          this.currentOffsetManeuver,
          this.currentLimitManeuver
        )
        .then((response) => {
          const amuraManeuvers = response.data.Data;
          const config = {};
          const rows = [];
          amuraManeuvers.forEach((element) => {
            let newRow = [];
            newRow.push({ value: element.AdditionalData.VesselName });
            newRow.push({ value: element.Maneuver });
            // newRow.push({ value: element });
            // newRow.push({ value: element });
            // newRow.push({ value: element });
            newRow.push({ value: element.AdditionalData.AssignedTugs });
            rows.push(newRow);
          });
          const totalRecords = JSON.parse(
            response.headers["pagination-headers"]
          ).totalCount;

          const tableResponse = new TableResponse(config, rows, totalRecords);

          this.rowsManeuver = tableResponse.rows;
          this.totalRecordsManeuver = tableResponse.totalRecords;
          this.loadingManeuver = "false";
        })
        .catch((error) => {
          console.log(error);
          //this.loadingManeuver = "false";
          if (error.response.status == 401) {
            this.handleRefresh();
          }
        });
    },
    loadForecast() {
      this.loadingForecast = "true";
      mdesService
        .getForecastList(this.currentOffsetForecast, this.currentLimitForecast)
        .then((response) => {
          const amuraManeuvers = response.data.Data;
          const config = {};
          const rows = [];
          amuraManeuvers.forEach((element) => {
            let newRow = [];
            // newRow.push({ value: element });
            newRow.push({ value: element.Maneuver });
            newRow.push({
              value: this.formatDate(element.AdditionalData.EstimatedTimeStart),
            });
            newRow.push({
              value: element.VendorExtension.AverageManeuverTime,
            });
            newRow.push({ value: element.AdditionalData.VesselName });
            newRow.push({
              value: element.AdditionalData.Destination.CompleteName,
            });
            newRow.push({
              value: element.AdditionalData.Destination.SideAlongSide,
            });
            newRow.push({ value: element.AdditionalData.AssignedTugs });
            let remarks = "";
            element.AdditionalData.Remarks.forEach((remark) => {
              remarks += remark.Description + " ";
            });
            newRow.push({ value: remarks });
            rows.push(newRow);
          });
          const totalRecords = JSON.parse(
            response.headers["pagination-headers"]
          ).totalCount;

          const tableResponse = new TableResponse(config, rows, totalRecords);

          this.rowsForecast = tableResponse.rows;
          this.totalRecordsForecast = tableResponse.totalRecords;
          this.loadingForecast = "false";
        })
        .catch((error) => {
          this.loadingForecast = "false";
          if (error.response.status == 401) {
            this.handleRefresh();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../web-components/dynamic-list-component/styles.css";

h1 svg {
  vertical-align: bottom;
}
.logout-button.theme--light.v-btn {
  margin-left: 30px;
}
.v-progress-circular {
  margin: 1rem;
}
.table-component-container {
  margin-top: 50px;
}
.table-container {
  padding: 2%;
}
.table-title {
  color: #fff;
  color: #c0f4b3;
  font-weight: 300;
  font-size: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.iconPopClose {
  background-color: black;
  border-radius: 50px;
}
h2 {
  line-height: 1 !important;
  font-size: 12pt !important;
}
.arrow_box {
  position: relative;
  background: green;
  border: 1 px solid green;
}

.arrow_boxS {
  position: relative;
  background: #abb7b7;
  border: 1 px solid #abb7b7;
}

.arrow_boxE {
  position: relative;
  background: #b877db;
  border: 1 px solid #b877db;
}

.arrow_boxM {
  position: relative;
  background: #f5fa5e;
  border: 1 px solid #f5fa5e;
}

.arrow_boxP {
  position: relative;
  background: #f5fa5e;
  border: 1 px solid #f5fa5e;
}

.bocadillo {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: green;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.bocadilloS {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #abb7b7;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}

.bocadilloE {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #293a5e;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}

.bocadilloM {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #abed00;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}
.bocadilloP {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #f5fa5e;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.bocadilloPU {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #ff4b66;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.TituloShip {
  color: white;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size: 9pt;
}

.TituloShipP {
  color: black;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size: 9pt;
}

.bocadillo .textoBocadillo,
.bocadilloS .textoBocadillo,
.bocadilloE .textoBocadillo,
.bocadilloM .textoBocadillo,
.bocadilloP .textoBocadillo,
.bocadilloPU .textoBocadillo {
  background-color: #f0fed3;
  color: black;
  line-height: 0.5;
  /*margin-top: 7px;*/
  font-size: 11pt;
  padding: 11px;
  padding-bottom: 1px;
  border-radius: 10px;
  padding-left: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.dialogBox {
  width: 100%;
  height: 219px !important;
}
.botonClose {
  width: "100%";
  text-align: right;
}

.logoAmura {
  max-width: 150px;
}

.botonClosePopup {
  margin-top: -44px;
  margin-left: 176px;
  clear: both;
  position: absolute;
}

.txtCard {
  width: "100%";
  text-align: center !important;
}
.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
  background: white !important;
  color: black !important;
}
.v-card {
  width: 100%;
  height: 200px;
  -webkit-box-shadow: 0 2px 2px -2px rgb(0 0 0 / 20%),
    0 2px 2px -2px rgb(0 0 0 / 14%), 0 2px 5px -5px rgb(0 0 0 / 12%);
  box-shadow: 0 2px 2px -2px rgb(0 0 0 / 20%), 0 2px 2px -2px rgb(0 0 0 / 14%),
    0 2px 5px -5px rgb(0 0 0 / 12%);
  text-decoration: none;
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
button {
  width: 20%;
  height: 54px;
}
.pin {
  width: 75%;
}

.popupFlexCont {
  width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.popupFlexFoto {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.popupFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.itemBandera {
  width: 13% !important;
  border: 1px solid aqua;
  padding: 3px;
  background-color: aqua;
  border-radius: 9px;
}
.fotoBandera {
  width: 100%;
}

.itemNombreBarco {
  color: blue;
  text-transform: uppercase;
  font-weight: bolder;
}

.typeship {
  color: black;
  font-weight: 100;
  text-transform: none;
}

.iconoRotacion {
  transform: rotateY(60deg);
}

.tile,
tile:hover {
  color: "primary";
}
.tile:active {
  color: "white";
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #171c31 !important;
  box-shadow: none !important;
}

.theme--light.v-btn {
  color: #171c31 !important;
}

.v-toolbar__content {
  padding: 0px !important;
}

.borde {
  border: 5px white solid;
  border-radius: 25px;
}

.fondosal {
  border-color: #abb7b7;
  background-color: #abb7b7;
}

.fondoent {
  border-color: #b877db;
  background-color: #b877db;
}

.fondobc {
  border-color: #abed00;
  background-color: #abed00;
}
.fondoPOB {
  border-color: #f5fa5e;
  background-color: #f5fa5e;
}

.fondobar {
  border-color: #ff4b66;
  background-color: #ff4b66;
}

.fondobat {
  border-color: #84cbff;
  background-color: #84cbff;
}

.fondoet {
  border-color: #76c888;
  background-color: #76c888;
}

.textcolor {
  color: white;
}

.v-btn {
  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  /* align-items: center; */
  /* border-radius: 2px; */
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 6px -5px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  position: relative;
  /* vertical-align: middle; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-sheet {
  border-radius: 0px !important;
}
.fondoBarra {
  background-color: #2935a3;
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #171c31 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.detaBarra {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 58px;
}

.entradasBtm {
  width: 2vw;
  height: 2vw;
}

.incidentBtmBtm {
  width: 2vw;
  height: 2vw;
}

.buquesBtm {
  width: 2vw;
  height: 2vw;
}

.leaflet-layer {
  z-index: 1 !important;
}

/*//////////ESTILOS PORTRAIT ////////////////////////////////*/
@media only screen and (orientation: portrait) {
  .logoAmura {
    max-width: 150px;
    margin-left: 40px;
  }

  .entradasBtm {
    width: 8vw;
    height: 5vw;
  }

  .incidentBtmBtm {
    width: 4vw;
    height: 4vw;
  }

  .buquesBtm {
    width: 4vw;
    height: 4vw;
  }

  .botonClosePopup {
    margin-top: -4vh;
    margin-left: 24.5vw;
    clear: both;
    position: absolute;
  }

  .bocadilloPU {
    /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #ff4b66;
    position: absolute;
    top: calc(500 - var(--pointsX));
    left: var(--pointsY);
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
  }
}
</style>

<style lang="scss">
.titlePage {
  width: 100%;
  height: 53px;
  background-color: #01ca6e;
  color: white;
  text-align: center;
  padding: 11px;
  font-size: 25px;
}
.container.fluid {
  max-width: 100%;
  height: fit-content !important;
  padding: 7px;
}
.v-content__wrap {
  background-color: var(--v-primary-base);
}
</style>
